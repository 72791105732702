import * as React from "react";
import type { HeadFC } from "gatsby";
import Shell from "../components/shell/Shell";
import { CustomHead } from "../components/shell/CustomHead";
import ImageHero from "../components/shared/ImageHero";
import ImageCard from "../components/shared/cards/ImageCard";
import { StaticImage } from "gatsby-plugin-image";

const pageDescription =
  "Funding your business to success through our Project Equity Finance, Invoice Discounting, Debtor Financing, and Purchase Order Finance services.";
const pagesBreadcrumb = [
  {
    name: "Services",
    to: "/services",
  },
];

const ServicesPage = () => {
  return (
    <Shell>
      <ImageHero
        title="Our expert service to you"
        description={pageDescription}
        pages={pagesBreadcrumb}
      />
      <div className="bg-gray-100">
        <div className="mx-auto max-w-7xl py-12 px-4 sm:py-16 sm:px-6 lg:px-8">
          <div className="grid grid-cols-1 gap-12 md:grid-cols-3">
            <ImageCard
              title="Project Equity Finance"
              description="We become a funding partner to SMEs with credible transactions who are unable to access funding due to a lack of financial history or the inability to provide security that is normally required by traditional banks. This product is suitable for businesses with a valid purchase order or contract from a corporate company, State Owned Entity (SOE), most municipalities and government departments."
            >
              <StaticImage
                className="w-full"
                quality={100}
                src="../images/services-1.png"
                alt="A group of project managers outside at an industrial complex"
              />
            </ImageCard>
            <ImageCard
              title="Invoice Discounting/Debtor Financing"
              description="Businesses can convert existing sales invoices into instant cash as opposed to waiting for 30, 60, or 90 day payment terms. When the sale is made and the goods or services delivered, we will automatically pay you an advance of up to 90% of the invoice value."
            >
              <StaticImage
                className="w-full"
                quality={100}
                src="../images/services-2.png"
                alt="A wooden table with a financial spreadsheet, a pair of reading glasses, and a calculator"
              />
            </ImageCard>
            <ImageCard
              title="Purchase Order Finance"
              description="When a business has received a purchase order to supply goods, we fund the acquisition of the inventory/goods or raw material required to deliver on the purchase order."
            >
              <StaticImage
                className="w-full"
                quality={100}
                src="../images/services-3.png"
                alt="An ecommerce website displayed on a laptop with a couple of boxes and a bank card on top of it"
              />
            </ImageCard>
          </div>
        </div>
      </div>
    </Shell>
  );
};

export default ServicesPage;

export const Head: HeadFC = () => (
  <CustomHead title="Services" description={pageDescription} />
);
